<template>
    <modal ref="modalSetFechaImplementacion" titulo="Fecha de implementación" aceptar @aceptar="guardarValores">
        <ValidationObserver tag="div">
            <div class="row justify-content-center mx-0 py-3">
                <div class="col">
                        <p class="f-15 mb-3 text-center">Seleccione la fecha en la cual empezarán a regir estos valores</p>
                        <div class="text-center">
                            <div class="col">
                                <p class="f-12 pl-2 label-inputs">Fecha de implementación</p>
                                <el-date-picker v-model="fecha" type="date" format="dd [de] MMMM yyyy" placeholder="Selecciona la fecha" />
                            </div>
                        </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            fecha:''
        }
    },
    methods:{
        toggle(){
            this.$refs.modalSetFechaImplementacion.toggle()
        },
        guardarValores(){
            if(!this.fecha) return this.notificacion('','Por favor selecciona una fecha valida')

            this.notificacion('','Valores guardados correctamente','success')
            this.$emit('guardar',this.fecha)
            this.$refs.modalSetFechaImplementacion.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>

</style>